import React from 'react';
import { Link } from 'react-scroll';
import { formatLink } from '../../utils/tableOfContents';

const TableItem = ({ item }) => {
  const link = formatLink(item?.label);

  const addHandler = () => {
    const activeItem = document.querySelector('a.active');

    if (activeItem) {
      const links = document.querySelectorAll('.title-link');

      links.forEach(linkItem => {
        linkItem.classList.remove('active-tag');
      });

      const child = activeItem.firstElementChild;

      child.classList.add('active-tag');
    }
  };

  return (
    <li className="table-item">
      <Link
        to={link}
        spy
        activeClass="active"
        offset={-140}
        duration={300}
        delay={0}
        spyThrottle={0}
        onSetActive={() => addHandler()}
        onSetInactive={() => addHandler()}
      >
        <p
          className={`title-link level-${item.level}`}
          dangerouslySetInnerHTML={{ __html: item?.label }}
        />
      </Link>
    </li>
  );
};

export default TableItem;